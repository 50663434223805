<template>
  <!-- Тестовая страница для регистра мпк -->
  <div class="container-page">
    <div class="send-report">
      <p class="send-report__title title-inner">Отправка запроса</p>
      <div class="send-report__card">
        <div class="send-report__card-row">
          <div class="send-report__card-row-block">
            <p class="label">
              <span>Выберите тип получателя</span>
              <span id="recipientTypes" @click="selectAll">Выбрать всё</span>
            </p>
            <div class="custom-inMultiselect">
              <Multiselect
                placeholder="Выберите"
                :options="recipientTypes"
                mode="multiple"
                :hideSelected="false"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple-label="(values) => `${values.length} выбрано`"
                v-model="recipientTypeValues"
                v-on:select="recipientTypeChanged"
              >
                <template v-slot:option="{ option }">
                  <div class="multiselect-checkBox">
                    <div class="multiselect-checkBox__arrow"></div>
                  </div>
                  {{ option.label }}
                </template>
              </Multiselect>
            </div>

            <p class="result">
              <span>Выбраны:</span>
              <span
                v-for="item in recipientTypes.filter((x) => recipientTypeValues.includes(x.value))"
                :key="item.value"
              >
                {{ item.label }};
              </span>
            </p>
          </div>
          <div class="send-report__card-row-block">
            <p class="label">
              <span>Выберите получателя</span>
              <span id="recipients" @click="selectAll">Выбрать всё</span>
            </p>
            <p class="label">
              <span></span>
              <span @click="selectAllFoivs">Выбрать все ФОИВ</span>
            </p>
            <div class="custom-inMultiselect">
              <Multiselect
                placeholder="Выберите"
                :options="recipients"
                mode="multiple"
                :hideSelected="false"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple-label="(values) => `${values.length} выбрано`"
                v-model="recipientValues"
              >
                <template v-slot:option="{ option }">
                  <div class="multiselect-checkBox">
                    <div class="multiselect-checkBox__arrow"></div>
                  </div>
                  {{ option.label }}
                </template>
              </Multiselect>
              <div class="invalid-msg" v-if="v$.recipientValues.$error">
                Необходимо выбрать хотя бы одного получателя.
              </div>
            </div>
            <p class="label">Или введите адрес электронной почты</p>
            <input v-model="recipientsMailValue" placeholder="Введите получателя" type="email" />
            <p class="result">
              <span>Выбраны:</span>
              <span v-if="recipientsMailValue.length > 0"> {{ recipientsMailValue }}; </span>
              <span v-for="item in recipients.filter((x) => recipientValues.includes(x.value))" :key="item.value">
                {{ item.label }};
              </span>
            </p>
          </div>
        </div>
        <div class="send-report__card-row">
          <div class="send-report__card-row-block">
            <p class="label">Текст запроса</p>
            <ComponentInput v-model="body" type="textarea" placeholder="Введите текст запроса" required />
          </div>
          <div class="send-report__card-row-block">
            <p class="label">
              <span>Запрос предложений</span>
              <span id="suggestions" @click="selectAll">Выбрать всё</span>
            </p>
            <div class="custom-inMultiselect">
              <Multiselect
                placeholder="Выберите"
                :options="suggestions"
                mode="multiple"
                :hideSelected="false"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple-label="(values) => `${values.length} выбрано`"
                v-model="suggestionValues"
              >
                <template v-slot:option="{ option }">
                  <div class="multiselect-checkBox">
                    <div class="multiselect-checkBox__arrow"></div>
                  </div>
                  {{ option.label }}
                </template>
              </Multiselect>
              <div class="invalid-msg" v-if="v$.suggestionValues.$error">
                Необходимо отметить хотя бы одно предложение.
              </div>
            </div>
            <p class="result">
              <span>Выбраны:</span>
              <span v-for="item in suggestions.filter((x) => suggestionValues.includes(x.value))" :key="item.value">
                {{ item.label }};
              </span>
            </p>
          </div>
        </div>
        <div class="send-report__card-row">
          <div class="send-report__card-row-block">
            <p class="label">Дата ответа</p>
            <DateComponent v-model="answerDeadline" :lower-limit="today" required />
          </div>
        </div>
        <div class="send-report__card-row send-report__card-row-fullrow">
          <div class="send-report__card-row-block send-report__card-row-block-fullrow">
            <p class="label">Документы</p>
            <FileMultiUpload
              :entity="requestItem"
              prop-name="files"
              @uploaded="fileUploaded"
              @deleteFile="removeFile"
            />
          </div>
        </div>
      </div>

      <div class="send-report__btns">
        <ButtonStock title="Отправить" @click="postRequest" />
        <ButtonStock btnType="secondary" title="Отменить" @click="close" />
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';
  import { useVuelidate } from '@vuelidate/core';
  import { required, requiredIf } from '@vuelidate/validators';

  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import ComponentInput from '../../../common/components/ComponentInput';
  import DateComponent from '../../../common/components/dateComponent';
  import FileMultiUpload from '../../../common/components/FileMultiUpload';
  import DeptApi from '../../admin/api/department';
  import OrgApi from '../../admin/api/organization';
  import NsiApi from '../../nsi/api/nsi';
  import API from '../api/event';

  const recipientType = {
    foiv: 1,
    minec: 2,
    businness: 3,
  };

  export default {
    name: 'EventRequestForm',
    components: {
      ComponentInput,
      DateComponent,
      ButtonStock,
      Multiselect,
      FileMultiUpload,
    },
    data() {
      return {
        id: this.$route.params.id,
        recipientTypes: [
          { value: recipientType.foiv, label: 'ФОИВ' },
          { value: recipientType.minec, label: 'Министерство экономического развития РФ' },
          { value: recipientType.businness, label: 'Бизнес' },
        ],
        recipients: [],
        suggestions: [],
        recipientTypeValues: [],
        recipientValues: [],
        recipientsMailValue: '',
        suggestionValues: [],
        body: '',
        answerDeadline: null,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
        requestItem: {
          files: [],
        },
        isSelectAllFoiv: false,
        today: new Date(),
      };
    },
    created() {
      this.initForm();
      this.loadDictionaries();
    },
    methods: {
      initForm() {
        API.find(this.id)
          .then((response) => {
            const event = response.data;
            let schedule = [];
            if (event.eventDatesList?.length > 0) {
              event.eventDatesList.forEach((date) => {
                schedule.push(this.$momentFormat(date, 'DD MMMM YYYY HH:mm'));
              });
            }
            const dates = schedule.join(', ');
            this.body =
              `Информируем, что в соответствии с договоренностью с иностранной стороной ${dates} в г. ${event.place} запланировано проведение мероприятия "${event.name}".\n\n` +
              `С целью своевременной подготовки предстоящих мероприятий просим поручить направить в секретариат Российской части МПК :\n` +
              `1. Актуализированные справочные материалы по вопросам двустороннего сотрудничества.\n` +
              `2. Предложения по вопросам, которые целесообразно включить в повестку дня пленарного заседания МПК, а также встречи сопредседателей «в узком составе», и в случае наличия таковых, - тезисы к переговорам и необходимые справочные материалы.\n` +
              `3. Предложения по кандидатурам представителя организации (с указанием Ф.И.О., должности и контактного телефона) для участия в заседании Российской части и заседании МПК/встречи сопредседателей/рабочей группы.\n` +
              `4. Предложения для включения в итоговый протокол заседания МПК.`;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      postRequest() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return;
        }

        const request = {
          eventId: this.id,
          items: [],
        };

        if (this.recipientValues?.length) {
          this.recipientValues.forEach((recipientId) => {
            let item = {
              eventId: this.id,
              statusId: Constants.eventRequestStatus.new,
              answerDeadline: this.answerDeadline,
              email: this.recipientsMailValue,
              body: this.body,
              proposals: this.suggestionValues.map((s) => ({ requestTypeId: s, statusId: 1 })),
            };
            const recipient = this.recipients.find((t) => t.value === recipientId);
            if (recipient.type === recipientType.minec) {
              item.recipientDeptId = Number(recipientId.split(';')[0]);
              console.log(item.recipientDeptId);
            } else {
              item.recipientOrgId = Number(recipientId.split(';')[0]);
              console.log(item.recipientOrgId);
            }
            request.items.push(item);
          });
        } else if (this.recipientsMailValue?.trim()?.length) {
          const emails = this.recipientsMailValue.split(/[,;]\s*/);
          emails.forEach((email) => {
            const item = {
              eventId: this.id,
              statusId: Constants.eventRequestStatus.new,
              answerDeadline: this.answerDeadline,
              email: email,
              body: this.body,
              proposals: this.suggestionValues.map((s) => ({ requestTypeId: s, statusId: 1 })),
            };
            request.items.push(item);
          });
        } else {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return;
        }

        request.files = this.requestItem.files;

        API.postRequest(request)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'EventCard', params: { id: this.id, action: 'edit' } });
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      loadDictionaries() {
        NsiApi.search('mpkproposalrequesttype', this.defaultRequest)
          .then((response) => {
            this.suggestions = response.data.items.map((x) => {
              return { label: x.name, value: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      selectAll(e) {
        let refSelectAll = e.target.getAttribute('id');
        let items = [];
        switch (refSelectAll) {
          case 'suggestions':
            this.suggestions.forEach((item) => {
              items.push(item.value);
            });
            this.suggestionValues = items;
            break;
          case 'recipients':
            this.recipients.forEach((item) => {
              items.push(item.value);
            });
            this.recipientValues = items;
            break;
          case 'recipientTypes':
            this.recipientTypes.forEach((item) => {
              items.push(item.value);
            });
            this.recipientTypeValues = items;
            this.recipientTypeChanged();
            break;
        }
      },
      selectAllFoivs() {
        this.isSelectAllFoiv = true;
        this.recipientTypeValues = [];
        this.recipientValues = [];

        //setTimeout(() => {
        this.recipients = [];
        this.recipientTypeValues = [this.recipientTypes[0].value];
        const request = Object.assign({ typeIds: [Constants.organizationType.foiv] }, this.defaultRequest);
        OrgApi.searchShortFormat(request).then((response) => {
          this.recipients = response.data.items
            .filter((x) => x.id != Constants.organizationMinekId)
            .map((x) => ({ label: x.name, value: x.id + ';' + recipientType.foiv, type: recipientType.foiv }));
          let items = [];
          this.recipients.forEach((item) => {
            items.push(item.value);
          });
          this.$nextTick(() => {
            this.recipientValues = items;
            this.isSelectAllFoiv = false;
          });
        });
        //}, 100);
      },
      processFetchedItems(response, selectedItems, recipientType) {
        let items = response.data.items
          .filter((x) => x.id != Constants.organizationMinekId)
          .map((x) => ({ label: x.name, value: x.id + ';' + recipientType, type: recipientType }));
        this.recipients = this.recipients.concat(items);

        items.forEach((item) => {
          if (selectedItems.includes(item.value)) this.recipientValues.push(item.value);
        });
      },
      recipientTypeChanged() {
        if (this.isSelectAllFoiv) return;

        let selectedItems = [];
        this.recipientValues.forEach((item) => {
          selectedItems.push(item);
        });
        this.recipientValues = [];

        this.recipients = [];
        if (this.recipientTypeValues.includes(recipientType.foiv)) {
          const request = Object.assign({ typeIds: [Constants.organizationType.foiv] }, this.defaultRequest);
          OrgApi.searchShortFormat(request)
            .then((response) => {
              this.processFetchedItems(response, selectedItems, recipientType.foiv);
            })
            .catch((error) => {
              console.error(error?.response?.data);
            });
        }
        if (this.recipientTypeValues.includes(recipientType.minec)) {
          const request = Object.assign({ organizationIds: [Constants.organizationMinekId] }, this.defaultRequest);
          DeptApi.search(request)
            .then((response) => {
              this.processFetchedItems(response, selectedItems, recipientType.minec);
            })
            .catch((error) => {
              console.error(error?.response?.data);
            });
        }
        if (this.recipientTypeValues.includes(recipientType.businness)) {
          let request = Object.assign({ typeIds: [Constants.organizationType.external] }, this.defaultRequest);
          OrgApi.searchShortFormat(request)
            .then((response) => {
              this.processFetchedItems(response, selectedItems, recipientType.businness);
            })
            .catch((error) => {
              console.error(error?.response?.data);
            });
        }
      },
      close() {
        this.$router.push({ name: 'EventCard', params: { id: this.id, action: 'edit' } });
      },
      fileUploaded(e) {
        this.requestItem.files.push(e.data);
      },
      removeFile(e) {
        const file = this.requestItem.files.find((x) => x.id === e.id);
        const index = this.requestItem.files.indexOf(file);
        if (index > -1) {
          this.requestItem.files.splice(index, 1);
        }
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        recipientValues: {
          requiredIf: requiredIf(function () {
            return !this.recipientsMailValue;
          }),
        },
        recipientsMailValue: {
          requiredIf: requiredIf(function () {
            return !this.recipientValues?.length;
          }),
        },
        suggestionValues: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .send-report {
    &__card {
      padding: 30px;
      max-width: 934px;
      width: 100%;
      margin-bottom: 36px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      &-row {
        &-fullrow {
          max-width: 100%;
        }
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 14px;
        border-bottom: 1px dashed #d7d7d7;
        &:last-of-type {
          margin-bottom: 0px;
        }
        &-block {
          &-fullrow {
            max-width: 100%;
          }
          max-width: 428px;
          width: 100%;
          input,
          textarea {
            width: 100%;
          }
          textarea {
            height: 180px;
          }
          input,
          .multiselect,
          textarea {
            margin-bottom: 18px;
          }
          p {
            margin-bottom: 14px;
            &.result span:nth-of-type(1),
            &.label {
              font-weight: 500;
            }
            &.label {
              display: flex;
              justify-content: space-between;
              span {
                &:last-of-type {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
            &.result span:not(:nth-of-type(1)) {
              margin-left: 2px;
            }
          }
        }
      }
    }
    &__btns {
      display: flex;
      justify-content: space-between;
      max-width: 934px;
      width: 100%;
      button {
        max-width: 221px;
        width: 100%;
      }
    }
  }
  // Media
  @media (max-width: 985px) {
    .send-report__card-row {
      flex-wrap: wrap;
    }
    .send-report__card-row-block {
      max-width: unset;
    }
  }
  @media (max-width: 767px) {
    .send-report__card {
      padding: 30px 16px;
    }
    .send-report__btns {
      flex-wrap: wrap;
    }
    .send-report__btns button {
      margin-bottom: 8px;
      max-width: unset;
    }
  }
</style>
